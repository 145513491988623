import { makeStyles } from '@mui/styles';
import { Copy, FilePdf, FilePng, FileXls, ThumbsDown, ThumbsUp } from 'apollo-react-icons';
import IconButton from 'apollo-react/components/IconButton';
import Tooltip from 'apollo-react/components/Tooltip';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ANCHOR_TAG_NAME, COPY_ICON_TOOLTIP_TEXT, DOWNLOAD, DOWNLOAD_CSV_ICON_TOOLTIP_TEXT, DOWNLOAD_PDF_ICON_TOOLTIP_TEXT, DOWNLOAD_PNG_ICON_TOOLTIP_TEXT, ERROR, FEEDBACK_ERROR_MESSAGE, FEEDBACK_MESSAGE, THUMBSDOWN_ICON_TOOLTIP_TEXT, THUMBSUP_ICON_TOOLTIP_TEXT } from '../../../Constants';
import { AuditAction, AuditCategory, AuditSubCategory } from '../../../Constants/Enums';
import { showBanner } from '../../../Redux/Slice/BannerSlice';
import { AiApi } from '../../../Redux/apis/AiApi';
import logUserAction from '../../../Service/AuditLogService';
import { ConvAIUtils } from '../../../Utils/ConvAIUtils';
import { InfoMessage } from '../../../components/InfoMessage/InfoMessage';
import { AIFeedbackForm } from './AIFeedbackForm';

const aiFeedbackStyles = makeStyles({
    selectedFeedbackIcon: {
        backgroundColor: "#4D4D4D",
        color: "#FFFFFF",
        "&:hover": {
            backgroundColor: '#0557D500',
            color: '#4D4D4D'
        },
        "&:active": {
            backgroundColor: "#0557D529",
            color: '#4D4D4D'
        }
    },
    iconStyle: {
        color: "#4D4D4D7A",
        "&:hover": {
            backgroundColor: '#0557D500',
            color: '#4D4D4D'
        },
        "&:active": {
            backgroundColor: "#0557D529",
            color: '#4D4D4D'
        }
    }
});

export const AIFeedbackIconBar = (props) => {
    const { chat } = props;
    const imageFilePath = chat.responseImageFileLocation;
    const alteredImageFilePath = imageFilePath?.replace(/\\/g, "/");
    const imageFileUrl = encodeURIComponent(alteredImageFilePath);
    const dispatch = useDispatch();
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [rateChat] = AiApi.useRateChatMutation();
    const [downloadFile] = AiApi.useLazyDownloadFileByPathQuery();
    const [downloadImageAsPdf] = AiApi.useLazyDownloadImageAsPdfByPathQuery();

    const handleRating = async (ratingVal) => {
        return rateChat({ chatId: chat.id, body: { ratingAccuracy: ratingVal } }).unwrap()
            .then(r => {
                return true
            })
            .catch(e => {
                dispatch(showBanner({
                    variant: ERROR,
                    message: FEEDBACK_ERROR_MESSAGE
                }))
                return false
            })
    }

    const handleClickPdfDownload = async () => {
        const isDownloaded = await downloadImageAsPdf(imageFileUrl).unwrap()
            .then(r => {
                const img = URL.createObjectURL(r);
                const link = document.createElement("a");
                link.href = img;
                link.setAttribute("download", "image.pdf");
                document.body.appendChild(link);
                link.click();
                return true;
            }).catch(e => {
                return false;
            });

        if (isDownloaded) {
            await logUserAction(AuditCategory.CONV_AI, AuditSubCategory.DOWNLOAD_PDF, AuditAction.DOWNLOAD, {
                chatId: chat.id,
                studyId: chat.studyId,
                sessionId: chat.sessionId,
                filePath: imageFileUrl
            });
        }
    }

    const handleDownloadFile = async (filePath: string) => {
        const isDownloaded = await downloadFile(ConvAIUtils.getEnhancedFilePath(filePath)).unwrap()
            .then(r => {
                const img = URL.createObjectURL(r)
                const link = document.createElement(ANCHOR_TAG_NAME);
                link.href = img;
                link.setAttribute(DOWNLOAD, ConvAIUtils.getFileNameFromPath(filePath));
                document.body.appendChild(link);
                link.click();
                return true;
            }).catch(e => {
                return false;
            })
        if (isDownloaded) {
            console.log('downloaded')
            await logUserAction(AuditCategory.CONV_AI, filePath.endsWith('.csv') ? AuditSubCategory.DOWNLOAD_CSV : AuditSubCategory.DOWNLOAD_PNG, AuditAction.DOWNLOAD, {
                chatId: chat.id,
                studyId: chat.studyId,
                sessionId: chat.sessionId,
                filePath: filePath
            });
        }
    };
    const handleCopyClick = async () => {
        const isCopied = await downloadFile(imageFileUrl).unwrap()
            .then(r => {
                const img = URL.createObjectURL(r)
                const image = new Image();
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const callbackFn = (callback) => {
                    canvas.toBlob(blob => {
                        callback(blob);
                    }, 'image/png');
                }
                const setCanvasImage = (imgPath, callback) => {
                    image.onload = (e) => {
                        if (ctx) {
                            canvas.height = image.height;
                            canvas.width = image.width;
                            ctx.drawImage(image, 0, 0, image.width, image.height);
                        }
                        callbackFn(callback)
                    };
                    image.src = imgPath;
                }
                setCanvasImage(img, imgBlob => {
                    window.navigator.clipboard.write([new ClipboardItem({ 'image/png': imgBlob })])
                        .then(() => {
                            console.log('Image copied to clipboard');
                        })
                        .catch(error => {
                            console.error(error);
                        });
                });
                return true
            })
            .catch(e => {
                return false
            })
        if (isCopied) {
            console.log('copied')
        } else {
            console.log('failed while copying')
        }
    };
    const handleThumbsDownClick = async () => {
        const ratingVal = chat.ratingAccuracy === 1 ? 0 : 1
        const isRated = await handleRating(ratingVal);
        if (isRated && ratingVal === 1) {
            setShowFeedbackForm(true);
        }
    }
    const handleThumbsUpClick = async () => {
        const ratingVal = chat.ratingAccuracy === 5 ? 0 : 5
        await handleRating(ratingVal)
    }
    const classes = aiFeedbackStyles();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', margin: '5px 5px 5px 34px' }}>
            <div>
                <Tooltip variant='light' title={THUMBSUP_ICON_TOOLTIP_TEXT} placement='bottom'>
                    <IconButton
                        data-testid='thumbs-up'
                        disableRipple
                        className={chat.ratingAccuracy === 5 ? classes.selectedFeedbackIcon : classes.iconStyle}
                        size={'small'}
                        onClick={handleThumbsUpClick}>
                        <ThumbsUp />
                    </IconButton>
                </Tooltip>
                <Tooltip variant='light' title={THUMBSDOWN_ICON_TOOLTIP_TEXT} placement='bottom'>
                    <IconButton
                        data-testid='thumbs-down'
                        size={'small'}
                        disableRipple
                        className={chat.ratingAccuracy === 1 ? classes.selectedFeedbackIcon : classes.iconStyle}
                        onClick={handleThumbsDownClick}>
                        <ThumbsDown />
                    </IconButton>
                </Tooltip>
                <Tooltip variant='light' title={COPY_ICON_TOOLTIP_TEXT} placement='bottom'>
                    <span>
                        <IconButton
                            data-testid='copy'
                            size={'small'}
                            onClick={handleCopyClick}
                            disableRipple
                            disabled={!chat.responseImageFileLocation}
                            className={classes.iconStyle}>
                            <Copy />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip variant='light' title={DOWNLOAD_PNG_ICON_TOOLTIP_TEXT} placement='bottom'>
                    <span>
                        <IconButton
                            data-testid='download-png'
                            size={'small'}
                            disabled={!chat.responseImageFileLocation}
                            disableRipple
                            className={classes.iconStyle}
                            onClick={() => handleDownloadFile(chat.responseImageFileLocation)}>
                            <FilePng />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip variant='light' title={DOWNLOAD_PDF_ICON_TOOLTIP_TEXT} placement='bottom'>
                    <span>
                        <IconButton
                            data-testid='download-pdf'
                            size={'small'}
                            disabled={!chat.responseImageFileLocation}
                            disableRipple
                            className={classes.iconStyle}
                            onClick={handleClickPdfDownload}>
                            <FilePdf />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip variant='light' title={DOWNLOAD_CSV_ICON_TOOLTIP_TEXT} placement='bottom'>
                    <span>
                        <IconButton
                            data-testid='download-dataset'
                            size={'small'}
                            disabled={!chat.datasetPath}
                            disableRipple
                            className={classes.iconStyle}
                            onClick={() => handleDownloadFile(chat.datasetPath)}>
                            <FileXls />
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
            {(chat.dislikeTagName || chat.feedback) &&
                <InfoMessage
                    data-testid='feedback-info'
                    message={FEEDBACK_MESSAGE}
                    disable={true}
                    forConvAI
                />}
            {(chat.ratingAccuracy === 1 && showFeedbackForm) &&
                <div>
                    <AIFeedbackForm chat={chat} setShowFeedbackForm={setShowFeedbackForm} />
                </div>
            }
        </div>
    )
}