import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BLOB } from '../../Constants';
import { getCaApiHeaders } from '../../Service/ApiHeaderService';

export const AiApi = createApi({
    reducerPath: 'aiApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/ai/chats`,
        headers: getCaApiHeaders()
    }),
    tagTypes: ['chatsBySession', 'sessions'],
    endpoints: (builder) => ({
        fetchChatById: builder.query({
            query: ({ chatId }) => {
                return {
                    url: `/${chatId}`,
                    method: 'GET'
                }
            },
        }),
        fetchChatsBySession: builder.query({
            query: ({ studyId, sessionId }) => {
                return {
                    url: `/study/${encodeURIComponent(studyId)}/sessions/${sessionId}`,
                    method: 'GET'
                }
            },
            providesTags: ['chatsBySession']
        }),
        downloadFileByPath: builder.query({
            query: (path) => {
                return {
                    url: `/image/${path}`,
                    method: 'GET',
                    responseType: 'arraybuffer',
                    responseHandler: (response) => response.blob()
                }
            }
        }),
        downloadImageAsPdfByPath: builder.query({
            query: (path) => {
                return {
                    url: `/image/pdf/${path}`,
                    method: 'GET',
                    responseType: BLOB,
                    responseHandler: (response) => response.blob()
                }
            }
        }),
        fetchSessions: builder.query({
            query: ({ studyId }) => {
                return {
                    url: `/study/${encodeURIComponent(studyId)}/sessions`,
                    method: 'GET'
                }
            },
            providesTags: ['sessions']
        }),
        processChat: builder.mutation({
            query: (body) => {
                return {
                    url: '/',
                    method: 'POST',
                    body: { ...body }
                }
            },
            invalidatesTags: ['chatsBySession', 'sessions'],
        }),
        renameSession: builder.mutation({
            query: ({ studyId, sessionId, sessionName }) => {
                return {
                    url: `/study/${encodeURIComponent(studyId)}/sessions/${sessionId}/rename`,
                    method: 'PATCH',
                    body: { sessionName: sessionName }
                }
            },
            invalidatesTags: ['sessions']
        }),
        rateChat: builder.mutation({
            query: ({ chatId, body }) => {
                return {
                    url: `/${chatId}/feedback`,
                    method: 'PATCH',
                    body: { ...body }
                }
            },
            invalidatesTags: ['chatsBySession']
        }),
        fetchDislikeTags: builder.query({
            query: () => {
                return {
                    url: `/feedback/dislike-tags`,
                    method: 'GET'
                }
            }
        }),
    })
});