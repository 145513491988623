import Button from 'apollo-react/components/Button';
import CircularProgress from 'apollo-react/components/CircularProgress';
import Typography from 'apollo-react/components/Typography';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import { HIDE_DETAILS, VIEW_DETAILS, WORKING } from '../../../Constants';
import { AiApi } from '../../../Redux/apis/AiApi';
import { RootState } from '../../../Redux/store';
import { ScrollToBottom } from '../../../components/generic/ScrollToBottom';

export const DetailsTab = ({ chat }) => {
    const POLLING_INTERVAL: any = process.env.REACT_APP_AI_FETCH_API_POLLING_INTERVAL ? process.env.REACT_APP_AI_FETCH_API_POLLING_INTERVAL : 3000;
    const chatDataRef: any = React.useRef();
    const [showDetails, setShowDetails] = useState(true);
    const selectedStudies = useSelector((state: RootState) => state.selectedStudiesSlice.selectedStudies)
    const [fetchChats] = AiApi.useLazyFetchChatsBySessionQuery();
    const { data: chatData } = AiApi.useFetchChatByIdQuery({ chatId: chat.id },
        {
            pollingInterval: POLLING_INTERVAL,
            skip: chatDataRef.current?.status === 1,
        });
    useEffect(() => {
        if (chatData) {
            chatDataRef.current = chatData;
            if (chatData?.status === 1) {
                fetchChats({
                    studyId: selectedStudies[0],
                    sessionId: chatData.sessionId
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatData]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'center', zIndex: '-1000' }}>
                <CircularProgress variant="indeterminate" statusText={WORKING} disableShrink={true} size='small' />
            </div>
            {chatData &&
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    {(chatData?.valueThought?.length > 0) &&
                        <div style={{ paddingLeft: '34px' }}>
                            <Button
                                component={'a'}
                                onClick={() => { setShowDetails(!showDetails) }}
                                style={{
                                    display: 'inline-block',
                                    padding: 0,
                                    minHeight: 0,
                                    minWidth: 0,
                                }}
                            >
                                {<Typography color={'#595959'} style={{ cursor: 'pointer' }}>{!showDetails ? VIEW_DETAILS : HIDE_DETAILS}</Typography>}
                            </Button>
                        </div>}
                    {showDetails &&
                        <div style={{ paddingLeft: '34px', display: 'flex', flexDirection: 'column' }}>
                            {chatData?.valueThought?.length > 0 && <div style={{ whiteSpace: "pre-line" }}>{ReactHtmlParser(chatData.valueThought.join('\n'))}</div>}
                        </div>}
                </div>}
            <ScrollToBottom dependencyList={[chatData]} />
        </div>
    );
}