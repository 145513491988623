import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Close from 'apollo-react-icons/Close';
import Divider from 'apollo-react/components/Divider';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { StudiesAutoCompleteDropdown } from '../../../components/studies/StudiesAutoCompleteDropdown';
import { BANNER_TEXT } from '../../../Constants';
import { setBannerOpened } from '../../../Redux/Slice/GenAiSlice';
import { RootState } from '../../../Redux/store';

const useStyles = makeStyles({
    banner: {
        background: '#4D4D4D',
        display: 'flex',
        height: '46px'
    },
    bannerText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '11px'
    },
    divContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        position: 'fixed',
        width: 'calc(100% - 70px)'
    },
    closeButton: { marginBottom: '15px' }
});

export const ConvAiHeader = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const bannerOpened = useSelector((state: RootState) => state.genAi.bannerOpened);

    return (
        <div className={classes.divContainer}>
            <StudiesAutoCompleteDropdown width={'45%'} forConvAI />
            <div style={{ height: '48px' }}>
                <Divider style={{ borderBottomWidth: '2px', borderColor: 'black' }} />
                {bannerOpened &&
                    <Grid container className={classes.banner}>
                        <Grid item xs>
                            <div className={classes.bannerText}>
                                <Typography variant="caption" darkMode>
                                    {BANNER_TEXT}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={0.5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton
                                className={classes.closeButton}
                                data-testid={'banner-close'}
                                onClick={() => { dispatch(setBannerOpened(false)) }}>
                                <Close size="small" style={{ color: '#FFFFFF' }} />
                            </IconButton>
                        </Grid>
                    </Grid>}
            </div>
        </div>
    );
}