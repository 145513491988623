export const LOGOUT_PATH = '/logout';
export const EMBEDDED_PATH = '/embedded';
export const HOME_PATH = '/home';
export const EMBEDDED = 'embedded';
export const USER_ID = 'user.id';
export const USER_TOKEN = 'user.token';
export const API_KEY = 'api-key';
export const ACCESS_TOKEN = 'access-token';
export const TENANT = 'tenant';
export const ALPHATAU = 'alphatau';
export const LAUNCHPAD_CDI = 'Launchpad-CDI';
export const LAUNCHPAD_CDM = 'Launchpad-CDM';
export const LAUNCHPAD_CDR = 'Launchpad-CDR';
export const LAUNCHPAD_CA = 'Launchpad-CA';
export const LAUNCHPAD_DSW = 'Launchpad-DSW';
export const NOACCESS_MODEL_TITLE = 'No Access to Clinical Analytics';
export const NOACCESS_MODEL_MSG = 'Please reach out support team for Clinical Analytics reports access';
export const ERROR_MSG_TITLE = 'Something went wrong';
export const DOWNLOAD_ERROR_MSG = 'Exception occured while downloading the exported file';
export const GENERAL_ERROR_MSG = 'Something went wrong. Please contact technical support';
export const LOGOUT_ERROR = 'Error: There is some error in logging out!';
export const NAME_REQUIRED = 'Report name is required';
export const MAX_CHAR_ALLOWED = 'Maximum 50 characters are allowed';
export const INVALID_REPORT_NAME = 'Invalid Report name';
export const DUPLICATE_REPORT_NAME = 'Report with this name already exists. Choose different report name.'
export const NEW_CUSTOM_DASHBOARD = 'New Self Service Report';
export const SELF_SERVICE_REPORT = 'Self Service Report';
export const LIBRARY_OF_VISUALIZATION = 'Library of Visualizations';
export const SAVE_LABEL = 'Save';
export const CANCEL_LABEL = 'Cancel';
export const DEFAULT = 'default';
export const ERROR = 'error';
export const USER_MANUAL_LINK = 'https://elvis.quintiles.com/livelink/llisapi.dll/fetch/-248738535/248799622/267784769/295066491/-/CDAS_CA_User_Manual_Dummy.pdf?nodeid=297390401&vernum=-2';
export const BLOB = 'blob';
export const PDF = 'PDF';
export const PPTX = 'PPTX';
export const DOWNLOAD_SUCCESS_MSG = 'Successfully downloaded the exported file';
export const REPORT_ID_PARAM = 'reportId=';
export const WORKSPACE_ID_PARAM = '&groupId=';
export const CONFIG_PARAM = '&w=2&config';
export const JWT_PARAM = '&jwt=';
export const AUTH_PARAM = '?pbiAuthType=';
export const POWER_BI = 'PowerBI';
export const HOME_HEADER_TITLE = 'Obtain the insights from your study data';
export const STUDIES_HEADING = 'Select study(s)';
export const NEW_SELF_SERVICE_REPORT = "New Self Service Report";
export const ANALYTICS_CONTAINER_TITLE = 'Analytics';
export const STUDYS_LABEL = 'Study(s):';
export const GO_BUTTON_LABEL = 'Go';
export const ANALYTIC_LABEL = 'Analytic:';
export const NOTE = 'Note: ';
export const SINGLE_STUDY_INFO_MESSAGE = "Based on your selection of multiple studies, single-study only visualizations are disabled below.";

export const COMP_PBICONFIG = 'PBIConfig';
export const COMMENT = 'comment';
export const STUDIES_DROPDOWN_HELPER_TEXT = "Please select at least one study.";
export const STUDIES_PACEHOLDER_TEXT = "Select a study";
export const DISABLE_STUDIES_LENGTH_ARR = [0, 1];

export const DOWNLOAD = 'download';
export const ANCHOR_TAG_NAME = 'a';

//Converse Page
export const NO_CONVERSATIONS_INFO = "No previous conversations for this study.";
export const BANNER_TEXT = "This feature is in beta release.";
export const TODAY = "Today";
export const EARLIER = "Earlier";
export const MY_CONVERSATIONS = "My Conversations";
export const STUDY = "Study: ";
export const NEW_CONVERSATION = "New Conversation";
export const FOOTER_TEXT = "AI Assistant is powered by AI and may produce inaccurate information. Consider verifying responses.";
export const PENCIL_ICON_TOOLTIP_TEXT = "Edit the conversation name";
export const PAPERPLANE_ICON_TOOLTIP_TEXT = "Send";
export const COPY_ICON_TOOLTIP_TEXT = "Copy to Clipboard";
export const THUMBSDOWN_ICON_TOOLTIP_TEXT = "Dislike";
export const THUMBSUP_ICON_TOOLTIP_TEXT = "Like";
export const DOWNLOAD_PNG_ICON_TOOLTIP_TEXT = "Download PNG";
export const DOWNLOAD_PDF_ICON_TOOLTIP_TEXT = "Download PDF";
export const DOWNLOAD_CSV_ICON_TOOLTIP_TEXT = "Download dataset as CSV";
export const CLINICAL_ANALYTICS = "Clinical Analytics";
export const WORKING = "Working";
export const BANNER_ERROR_TEXT = "Exception occurred while fetching details";
export const BANNER_ERROR_FOR_STUDY = "AI Assistant doesn't support this study yet";
export enum ConverseHelpPageConstants {
  AI_ASSIST_TITLE = "AI Assistant",
  FIRST_PARA = "Using this feature, you can ask questions about a study’s clinical data and receive AI driven responses in the form of data visualizations, metrics and/or conversational text.",
  WHAT_TO_KNOW = "What to know",
  SECOND_PARA = "The AI model is already provided with information about the clinical trial datasets so that you do not have to explain the data as part of asking your question.  However, just like with ordinary conversation, the more descriptive you are about your question, in general the better the response will be. The AI model has been optimized with human feedback. However, as the model is generating new content to form its response, the response should be validated. When possible, intermediate results will be provided to make it easier for you to validate the response.",
  EXAMPLE_QUESTIONS_HEADING = "Example questions you might ask",
}
export const EXAMPLE_QUESTIONS = [
  "What are the 5 most common adverse events among patients over age 65?",
  "What's the most common AE for patients with heights under 160cm?",
  "Draw a bar chart representing the adverse event distribution by subjects with respect to gender and ethnicity.",
  "Draw a bar chart for a summary of adverse events with different types of causality.  Split the above bar chart by subject gender.",
  "What is the trend month to month of adverse events by site?",
  "Summarize the adverse event count for all subjects per visit."
];
export const PLACEHOLDER_TEXT = "Ask a question about this study’s clinical trial data.";
export const NO_CONV_SELECTED = "No conversation selected";
export const SEARCH_BY_TITLE = "Search by title";
export const VIEW_DETAILS = "View Details";
export const HIDE_DETAILS = "Hide Details";
export const FEEDBACK_MESSAGE = "Thank you for your feedback.  It will be used to help improve responses.";
export const FEEDBACK_ERROR_MESSAGE = "Exception occured while rating the response";
export const FEEDBACK_COMMENT_HELPER_TEXT = "Do not include any private information.";
export const FEEDBACK_CARD_TITLE = "What did you dislike about the response? (Optional)";
export const FEEDBACK_CARD_SUBTITLE = "Help us understand what needs to be improved..";